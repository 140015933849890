<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <TitleCard title="Datos personales" subtitle="Datos ingresados por el cliente"></TitleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap text-xs-center>
            <v-flex xs6>
              <v-text-field v-model="detail.name" hide-details label="Nombre"></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-text-field
                v-model="detail.email"
                hide-details
                label="Email"
                :disabled="user.role.id === 5 || user.role.id === 4 || user.role.id === 3"
              ></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field v-model="detail.telephone" hide-details label="Telefono Fijo"></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field v-model="detail.phone" hide-details label="Telefono Movil"></v-text-field>
            </v-flex>
            <v-flex xs4>
              <v-text-field v-model="detail.birthday" hide-details label="Fecha de nacimiento"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-textarea
                v-model="detail.alert"
                label="Notificacion destacada"
                placeholder="*Este mensaje aparecera cuando se tome un pedido para el cliente*"
                hide-details
              ></v-textarea>
            </v-flex>
            <v-flex xs2>
              <v-radio-group v-model="detail.sexo" row>
                <v-radio label="Femenino" value="F" hide-details></v-radio>
                <v-radio label="Masculino" value="M" hide-details></v-radio>
              </v-radio-group>
            </v-flex>
            <v-flex xs3>
              <v-switch
                v-model="detail.status"
                label="Bloqueado"
                :false-value="true"
                :true-value="false"
                hide-details
                :disabled="!(user.role.id === 1 || user.role.id === 2)"
              >
              </v-switch>
            </v-flex>
            <v-flex xs3>
              <v-switch v-model="detail.mailmasivo" label="Notificacion Email" :value="true" hide-details></v-switch>
            </v-flex>
            <v-flex xs3>
              <v-switch v-model="detail.notificar_wsp" label="Notificacion Wsp" :value="true" hide-details></v-switch>
            </v-flex>
            <v-flex xs3>
              <v-switch
                v-model="detail.problem"
                label="Cliente Problematico"
                :value="true"
                hint="No se enviara encuestas de satisfaccion"
              ></v-switch>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider light></v-divider>
        <v-card-actions align-end class="text-xs-right" bottom>
          <v-btn
            v-if="user.role.id === 2 || user.role.id === 1 || user.role.id === 3"
            flat
            depressed
            color="error"
            @click="dialogForgetPassword = true"
          >
            Recuperacion de contraseña
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="user.role.id === 2 || user.role.id === 1 || user.role.id === 3"
            depressed
            flat
            color="accent"
            @click="updateBuyer"
          >
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialogForgetPassword" width="250" persistent>
      <v-card>
        <TitleDialog
          :title="'Enviar link'"
          :subtitle="'Recuperacion contraseña'"
          close
          :close-action="
            () => {
              dialogForgetPassword = false
            }
          "
        ></TitleDialog>
        <v-card-text>
          <v-layout wrap>
            <v-flex xs12 style="display:none;">
              <v-switch v-model="whatsApp" label="WhatsApp" hide-details></v-switch>
            </v-flex>
            <v-flex xs12>
              <v-switch v-model="email" label="Email" hide-details></v-switch>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat color="back" depressed @click="dialogForgetPassword = false">
            Volver
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="accent" depressed @click="recoveryPassword">
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { CLIENT } from '../../config'
import TitleCard from '../useful/titleCard.vue'
import TitleDialog from '../useful/titleDialog.vue'

export default {
  name: 'BuyerDetail',
  components: { TitleCard, TitleDialog },
  props: ['buyer', 'getBuyer'],
  data: () => ({
    dialogForgetPassword: false,
    whatsApp: false,
    email: true,
    active: true,
    stock: true,
    scheduleRetirement: 0,
    detail: {
      name: null,
      email: null,
      telephone: null,
      phone: null,
      birthday: null,
      alerta: null,
      sexo: null,
      status: null,
      mailmasivo: null,
      notificar_wsp: null
    },
    states: [
      { name: 'Florida', abbr: 'FL', id: 1 },
      { name: 'Georgia', abbr: 'GA', id: 2 },
      { name: 'Nebraska', abbr: 'NE', id: 3 },
      { name: 'California', abbr: 'CA', id: 4 },
      { name: 'New York', abbr: 'NY', id: 5 }
    ],
    windowHeight: window.innerHeight - (window.innerHeight * 80) / 100
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  watch: {
    buyer() {
      this.initBuyer()
    }
  },
  mounted() {
    this.initBuyer()
  },
  methods: {
    async updateBuyer() {
      try {
        const res = await this.$http.put(`${CLIENT}/${this.detail.id}/detail`, {
          user: this.detail
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: res.data.msj
        })
        this.getBuyer()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async recoveryPassword() {
      const { whatsApp, email } = this
      if (whatsApp || email) {
        try {
          await this.$http.post(`${CLIENT}/${this.detail.id}/recovery-password`, {
            userID: this.detail.id,
            whatsApp: this.whatsApp,
            email: this.email
          })
          this.dialogForgetPassword = false
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Link fue enviado'
          })
          this.getBuyer()
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      } else {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Seleccione una opcion'
        })
      }
    },
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.abbr.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
    },
    initBuyer() {
      const { buyer } = this
      this.detail = {
        id: buyer.id,
        name: this.$options.filters.toHTML(buyer.name),
        email: buyer.email,
        telephone: buyer.telephone,
        phone: buyer.phone,
        birthday: buyer.birthday,
        alert: this.$options.filters.toHTML(buyer.alert),
        sexo: buyer.sexo,
        status: buyer.status,
        mailmasivo: buyer.mailmasivo,
        notificar_wsp: buyer.notificar_wsp,
        problem: buyer.problem
      }
    }
  }
}
</script>

<style></style>
